import { useEffect, useState } from "react";
import ModalForSettings from "./ModalForSettings";
import ModalForSupplierFilter from "./ModalForSupplierFilter";
import ModalForHiddenProducts from "./ModalForHiddenProducts";
import ModalForProFeatures from "./ModalForProFeatures";
import { utils, writeFile } from 'xlsx';

function FilterBar(props) {
  const [modalSetting, setModalSetting] = useState("");
  const [searchText, setSearchText] = useState(props.filters.hasOwnProperty("search") ? props.filters.search : "");
  const [filterSupplierModalOpen, setFilterSupplierModalOpen] = useState(false);
  const [offersType, setOffersType] = useState(false);
  const [gatedType, setGatedType] = useState(false);
  const [moreSettingsModal, setMoreSettingsModal] = useState(false);
  const [bsrType, setBsrType] = useState(false);
  const [showHiddenProducts, setShowHiddenProducts] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [proModalOpen, setProModalOpen] = useState(false);
  const [categories, setCategories] = useState({
    "Kitchen & Dining": false,
    "Beauty & Personal Care": false,
    "Toys & Games": false,
    "Office Products": false,
    "Home & Kitchen": false,
    "Baby": false,
    "Unique Finds": false,
    "Sports & Outdoors": false,
    "Patio, Lawn & Garden": false,
    "Arts, Crafts & Sewing": false,
    "Tools & Home Improvement": false,
    "Automotive": false,
    "Industrial & Scientific": false,
    "Video Games": false,
    "Health & Household": false,
    "Our Brands": false,
    "Cell Phones & Accessories": false,
    "Pet Supplies": false,
    "Books": false,
    "Musical Instruments": false,
    "Clothing, Shoes & Jewelry": false,
    "Grocery & Gourmet Food": false,
    "Climate Pledge Friendly": false,
    "Electronics": false,
    "Amazon Renewed": false,
    "Amazon Devices & Accessories": false,
    "Computers & Accessories": false,
    "Appliances": false,
    "CDs & Vinyl": false,
    "Software": false,
    "Handmade Products": false,
    "Collectible Coins": false,
    "Movies & TV": false,
    "Camera & Photo Products": false,
  });
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [searchTextIndex, setSearchTextIndex] = useState(0);
  const [saveFiltersSlot, setSaveFiltersSlot] = useState(1);
  const [brandExc, setBrandExc] = useState("");
  const [salesTax, setSalesTax] = useState("");
  const [manualRender, setManualRender] = useState(15);
  const searchTextsPlaceholders = ['', 'P', 'Pr', 'Pro', 'Prod', 'Produ', 'Produc', 'Product', 'Product.', 'Product..', 'Product...',
    '', 'A', 'AS', 'ASI', 'ASIN', 'ASIN.', 'ASIN..', 'ASIN...',
    '', 'B', 'Br', 'Bra', 'Bran', 'Brand', 'Brand.', 'Brand..', 'Brand...']

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSearchTextIndex((prevIndex) => (prevIndex + 1) % searchTextsPlaceholders.length);
    }, 450);

    return () => clearInterval(intervalId);
  });

  const exportToExcel = () => {
    let formedData = []
    props.data.forEach(product => {
      formedData.push({
        "1. Amazon Link": product.asin,
        "2. Supplier Link": product.url.replace("X".repeat(product.source_name.length), product.source_name),
        "3. Name": product.title,
        "4. Category": product.category,
        "5. BSR": product.sales_rank,
        "6. AZ Offers": JSON.parse(product.az_offers).length,
        "7. Supplier Price": product.price,
        "8. AZ Price": product.az_price,
        "9. FBA Cost": product.fba_cost,
        "10. AZ Referral Fee": product.selling_on_az_fees,
        "11. ROI": product.roi * 100,
        "12. MOQ": product.moq,
        "13. Stock": product.qty,
        "14. ASIN": product.asin.split("/")[product.asin.split("/").length - 1],
      })
    });
    const ws = utils.json_to_sheet(formedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, 'Nepeto.csv');
  };

  return (
    <div
      className="columns is-centered is-multiline is-mobile"
      style={{
        textAlign: "center",
        marginTop: props.isMobile ? "1vh" : "8vh",
        marginLeft: ".5vw",
        marginRight: props.isMobile && ".5vw",
        verticalAlign: "middle",
      }}
      id="filterbarTt"
    >
      <div className="column is-2-desktop is-6-mobile">
        <div className="field has-addons">
          {!props.isMobile && <button
            className={"button is-rounded darkbutton"}
            style={{
              marginTop: "-5.5vh",
              marginLeft: ".2vw",
              backgroundColor: "#8B3C7E",
              color: "white",
              fontSize: props.isMobile ? "100%" : "0.85vw",
              position: "absolute",
            }}
            id="exportTt"
            onClick={() => {
              if (props.activePlanLevel !== "Pro Plan" && props.activePlanLevel !== "Master Plan") {
                alert("Only Pro & Master Plan subscribers can access this feature!")
              }
              else {
                exportToExcel()
              }
            }}
          >
            Export &nbsp;<i class="fa fa-download" style={{ paddingTop: "5px" }} aria-hidden="true"></i>
          </button>}
          {!props.isMobile && <label class="checkbox" style={{
            marginTop: "-4.5vh",
            marginLeft: "7.5vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            fontWeight: "700",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) =>
                props.setFilters((filters) => ({
                  ...filters,
                  amazonNotSeller: +e.target.checked,
                }))
              }
              checked={props.filters.amazonNotSeller || false}
            />
            &nbsp;&nbsp;Filter out if Amazon is one of the sellers
          </label>}
          {!props.isMobile && <label class="checkbox tooltip" style={{
            marginTop: "-4.5vh",
            marginLeft: "26.1vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) =>
                props.setFilters((filters) => ({
                  ...filters,
                  mustBB: +e.target.checked,
                }))
              }
              checked={props.filters.mustBB || false}
            />
            &nbsp;&nbsp;Must have Buy Box<span style={{ fontSize: "62%" }}>(?)</span>
            <span class="tooltiptext">
              The listing MUST have a Buy Box. Filter out any listings (ASINs) that DON'T have an active Buy Box.
            </span>
          </label>}
          {!props.isMobile && <label class="checkbox tooltip" style={{
            marginTop: "-4.5vh",
            marginLeft: "36.6vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) =>
                props.setFilters((filters) => ({
                  ...filters,
                  mustNotBB: +e.target.checked,
                }))
              }
              checked={props.filters.mustNotBB || false}
            />
            &nbsp;&nbsp;<strong>No</strong> Buy Box<span style={{ fontSize: "62%" }}>(?)</span>
            <span class="tooltiptext">
              The listing must NOT have a Buy Box. Filter out any listings (ASINs) that HAVE an active Buy Box.
            </span>
          </label>}
          {!props.isMobile && <label class="checkbox tooltip" style={{
            marginTop: "-4.5vh",
            marginLeft: "44.6vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) => props.setFilters((filters) => ({
                ...filters,
                offers: (props.filters.hasOwnProperty("offers") && props.filters['offers'][0] >= 4) ? [-1, -1] : [4, -1],
              }))}
              checked={props.filters.hasOwnProperty("offers") && props.filters['offers'][0] >= 4}
            />
            &nbsp;&nbsp;No Private Label<span style={{ fontSize: "62%" }}>(?)</span>
            <span class="tooltiptext">
              The listing must have 4+ AZOffers.
            </span>
          </label>}
          {!props.isMobile && <label class="checkbox tooltip" style={{
            marginTop: "-4.5vh",
            marginLeft: "54.4vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            position: "absolute"
          }} id="showdataTt">
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) => { const newState = !props.showAdv; props.setShowAdv(newState); localStorage.setItem("showAdv", newState) }}
              checked={props.showAdv}
            />
            &nbsp;&nbsp;Show Data<span style={{ fontSize: "62%" }}>(?)</span>
            <span class="tooltiptext">
              Show Nepeto's Advanced Data below each deal.
            </span>
          </label>}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width={props.isMobile ? "17vw" : "3.5vw"}
            height={"4.4vh"}
            viewBox="0 0 120.000000 120.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{ cursor: "pointer" }}
            onClick={() => window.location.reload()}
          >
            <g
              transform="translate(0.000000,111.000000) scale(0.0900000,-0.086000)"
              fill="#000000"
              stroke="none"
            >
              <path d="M813 1266 c-77 -25 -153 -94 -183 -167 l-12 -29 -247 0 c-266 0 -291 -5 -291 -53 0 -27 -6 -17 184 -277 l151 -205 5 -253 c5 -236 6 -255 24 -268 32 -23 50 -17 142 51 63 46 92 74 101 97 7 20 12 97 12 199 l1 166 58 82 57 82 51 -6 c30 -3 74 1 108 10 270 71 306 430 56 555 -56 28 -157 36 -217 16z m182 -82 c85 -40 145 -146 129 -231 -33 -185 -248 -257 -378 -127 -107 107 -78 286 56 353 52 26 145 28 193 5z m-384 -251 c8 -64 45 -130 99 -176 l40 -34 -18 -24 c-11 -13 -40 -55 -66 -92 l-46 -68 0 -178 0 -179 -55 -41 c-30 -22 -57 -41 -60 -41 -3 0 -5 96 -5 213 0 185 -2 217 -18 247 -10 19 -82 122 -160 228 -78 106 -142 195 -142 198 0 2 96 3 213 2 l212 -3 6 -52z" />
              <path d="M800 1070 c-8 -15 -5 -25 20 -53 l29 -34 -24 -23 c-28 -26 -32 -61 -11 -79 17 -14 41 -5 70 28 l19 21 34 -30 c28 -25 38 -28 53 -20 27 15 25 39 -7 72 l-27 28 27 28 c29 31 34 51 15 70 -18 18 -33 15 -68 -14 l-31 -27 -28 27 c-32 31 -56 33 -71 6z" />
            </g>
          </svg>

          <div className="control">
            <input
              className="input searchproduct"
              type="text"
              style={{
                backgroundColor: "rgb(179,127,170)",
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              value={searchText}
              placeholder={"Search " + searchTextsPlaceholders[searchTextIndex]}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div className="control">
            <button
              className="button is-success"
              style={{
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              onClick={() => {
                props.setFilters((filters) => ({
                  ...filters,
                  search: searchText,
                }));
              }}
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <details className="selectlight selectdropdown" style={{
          fontSize: props.isMobile ? "1.9vh" : ".85vw", border:
            props.filters &&
            props.filters.hasOwnProperty("category") &&
            "3px solid rgb(72,199,142)",
        }}>
          <div className="modal-background" onClick={(e) => {
            if (categoryDropdownOpen) {
              props.setFilters((filters) => ({
                ...filters,
                category: Object.keys(categories).sort().filter(key => categories[key] === true),
              }))
              if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
                let tempFilters = { ...props.filters }
                delete tempFilters['category']
                props.setFilters(tempFilters)
              }
            }
            setCategoryDropdownOpen(before => !before);; e.target.parentElement.open = false
          }} style={{ zIndex: 9, height: "200vh", backgroundColor: "transparent" }}></div>
          <summary onClick={() => {
            if (categoryDropdownOpen) {
              props.setFilters((filters) => ({
                ...filters,
                category: Object.keys(categories).sort().filter(key => categories[key] === true),
              }))
              if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
                let tempFilters = { ...props.filters }
                delete tempFilters['category']
                props.setFilters(tempFilters)
              }
            }
            setCategoryDropdownOpen(before => !before);
          }} style={{ backgroundColor: categoryDropdownOpen && "green", color: categoryDropdownOpen && "white", zIndex: 10, position: "relative" }}>{categoryDropdownOpen ? "Save" : "Category"}</summary>
          <ul>
            <li key={"SelectAll"}>
              <label><input type="checkbox" name={"SelectAll"} value={"SelectAll"}
                onChange={(e) => {
                  setCategories((prevCat) => {
                    const updatedCategories = { ...prevCat };

                    Object.keys(updatedCategories).forEach((key) => {
                      updatedCategories[key] = e.target.checked;
                    });

                    return updatedCategories;
                  })
                }} /> {Object.values(categories).every(value => value === true) ? "Unselect All" : "Select All"}</label>
            </li>
            {Object.keys(categories).sort().map((category, index) => {
              return <li key={index}>
                <label><input type="checkbox" name={category} value={category} checked={categories[category]}
                  onChange={(e) => { setCategories({ ...categories, [category]: e.target.checked }) }} /> {category}</label>
              </li>;
            })}
          </ul>
        </details>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("price")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("price") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          Price
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("roi")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("roi") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          ROI
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton tooltip"
          onClick={() => setBsrType(true)}
          style={{
            border:
              props.filters &&
              (props.filters.hasOwnProperty("bsr") ||
                props.filters.hasOwnProperty("top_bsr")) &&
              "3px solid rgb(72,199,142)",
          }}
        >
          BSR
          <span class="tooltiptext">
            Sales Rank
          </span>
        </button>
        <div
          id="modal-js-example"
          className={
            "modal" + (bsrType ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setBsrType(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                BSR Type
              </p>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className="button is-rounded lightbutton tooltip"
                  onClick={() => { setModalSetting("bsr"); setBsrType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("bsr") &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  Sales Rank #
                  <span class="tooltiptext" style={{ padding: "0%" }}>
                    Sales Rank Number
                  </span>
                </button>
                <button
                  className="button is-rounded lightbutton tooltip"
                  onClick={() => { setModalSetting("top_bsr"); setBsrType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("top_bsr") &&
                      "3px solid rgb(72,199,142)",
                  }}
                >
                  Top BSR %
                  <span class="tooltiptext" style={{ padding: "0%" }}>
                    % / Max Sales Rank
                  </span>
                </button>
              </div>
              <br />
              <label class="checkbox tooltip" style={{
                fontSize: props.isMobile ? "100%" : "1vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.5)" }}
                  onChange={(e) => {
                    props.setFilters((filters) => ({
                      ...filters,
                      excludeSubCat: +e.target.checked,
                    }))
                  }
                  }
                  checked={props.filters.excludeSubCat || false}
                />
                &nbsp;&nbsp;Exclude Sub Categories
              </label>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setBsrType(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setOffersType(true)}
          style={{
            border:
              props.filters &&
              (props.filters.hasOwnProperty("offers") ||
                props.filters.hasOwnProperty("fbaoffers") ||
                props.filters.hasOwnProperty("fbmoffers")) &&
              "3px solid rgb(72,199,142)",
          }}
        >
          AZOffers
        </button>
        <div
          id="modal-js-example"
          className={
            "modal" + (offersType ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setOffersType(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                AZOffers Type
              </p>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => { setModalSetting("offers"); setOffersType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("offers") &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  Total
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => { setModalSetting("fbaoffers"); setOffersType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("fbaoffers") &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  FBA
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => { setModalSetting("fbmoffers"); setOffersType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("fbmoffers") &&
                      "3px solid rgb(72,199,142)",
                  }}
                >
                  FBM
                </button>
              </div>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setOffersType(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setGatedType(true)}
          style={{
            border:
              props.filters &&
              (props.filters.hasOwnProperty("filterOutGated") ||
                props.filters.hasOwnProperty("showOnlyUngated")) &&
              "3px solid rgb(72,199,142)",
          }}
        >
          Gated
        </button>
        <div
          id="modal-js-example"
          className={
            "modal" + (gatedType ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setGatedType(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Filter by Gated Status
              </p>
              <br /><br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <label class="checkbox tooltip" style={{
                  fontSize: props.isMobile ? "100%" : "0.9vw",
                }}>
                  <input
                    type="checkbox"
                    style={{ transform: "scale(1.4)" }}
                    onChange={(e) => props.setFilters((filters) => ({
                      ...filters,
                      filterOutGated: (props.filters.hasOwnProperty("filterOutGated") ? !props.filters['filterOutGated'] : true),
                    }))}
                    checked={props.filters.hasOwnProperty("filterOutGated") && props.filters['filterOutGated']}
                  />
                  &nbsp;&nbsp;Filter out Gated ASINs<br />
                  <i
                    className="fa fa-lock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "red",
                      marginTop: "0.25vw"
                    }} />
                  <span style={{ position: "absolute", fontSize: "2.75vw", marginLeft: "-1.8vw", marginTop: "-0.65vw" }}>X</span>
                  <i
                    className="fa fa-unlock-alt"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "orange"
                    }} />
                  <i
                    className="fa fa-unlock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "green"
                    }} /><br /><span style={{ color: "green" }} className="tooltip">*Recommended<span style={{ fontSize: "75%" }}>(?)</span><span className="tooltiptext" style={{ height: "auto", padding: "0px", marginTop: "-10%" }}>See auto-approval and ungating opportunities</span></span>{" "}
                </label>
                <label class="checkbox tooltip" style={{
                  fontSize: props.isMobile ? "100%" : "0.9vw",
                  marginLeft: "auto",
                }}>
                  {!localStorage.getItem("unlockGatFilter") && <i className="fa fa-lock tooltip" onContextMenu={(event) => {
                    event.preventDefault(); // Prevents the default browser context menu
                    localStorage.setItem("unlockGatFilter", "1");
                  }}
                    style={{ fontSize: "5vw", position: 'absolute', zIndex: "999", marginLeft: "4vw", marginTop: "-0.5vw", textShadow: "2px 2px 40px black" }}>
                    <span className="tooltiptext" style={{ position: "absolute", fontSize: "20%", marginTop: "-65%", fontFamily: "sans-serif" }}>Unlock this filter by scheduling a 1-on-1 walkthrough!</span>
                  </i>}
                  <input
                    type="checkbox"
                    style={{ transform: "scale(1.4)" }}
                    onChange={(e) => {
                      if (localStorage.getItem("unlockGatFilter")) {
                        props.setFilters((filters) => ({
                          ...filters,
                          showOnlyUngated: (props.filters.hasOwnProperty("showOnlyUngated") ? !props.filters['showOnlyUngated'] : true),
                        }))
                      } else {
                        window.open("https://calendly.com/nepetooneonone/45min")
                      }
                    }}
                    checked={props.filters.hasOwnProperty("showOnlyUngated") && props.filters['showOnlyUngated']}
                  />
                  &nbsp;&nbsp;Show Only Ungated ASINs<br /><i
                    className="fa fa-lock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "red",
                      marginTop: "0.25vw"
                    }} />
                  <span style={{ position: "absolute", fontSize: "2.75vw", marginLeft: "-1.8vw", marginTop: "-0.65vw" }}>X</span>
                  <i
                    className="fa fa-unlock-alt"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "orange"
                    }} />
                  <span style={{ position: "absolute", fontSize: "2.75vw", marginLeft: "-1.8vw", marginTop: "-0.65vw" }}>X</span>
                  <i
                    className="fa fa-unlock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "green"
                    }} />{" "}
                </label>
              </div>
              <br /><br />
              <span style={{ width: "70%", color: "black", display: "block", margin: "auto" }}>
                *This filter removes gated ASINs from the page results (averaging 50). If you're gated on many products, you may see fewer results per page
              </span>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setGatedType(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.isMobile && <div className="column is-1-desktop" style={{ padding: "0", width: "auto", display: "table", verticalAlign: "middle" }}>
        &emsp;<span id="moreAdvTt" onClick={() => setMoreSettingsModal(true)} style={{
          textDecoration: "underline", width: "auto", cursor: "pointer", fontSize: "1.05vw", fontWeight: "500", display: "table-cell", verticalAlign: "middle",
          color:
            props.filters && (props.filters.hasOwnProperty("priceCoupon") || (props.filters.hasOwnProperty("moq") &&
              JSON.stringify(props.filters['moq']) !== JSON.stringify([-1, -1]) &&
              JSON.stringify(props.filters['moq']) !== JSON.stringify([-1, 24]) &&
              JSON.stringify(props.filters['moq']) !== JSON.stringify([24, -1])) ||
              props.filters.hasOwnProperty("sortBy") || props.filters.hasOwnProperty("profit") ||
              JSON.stringify(props.filters).includes("found_time") ||
              props.filters.hasOwnProperty("azprice") ||
              (props.filters.hasOwnProperty("oosRA") && props.filters["oosRA"]) ||
              (props.filters.hasOwnProperty("showFeesNA") && props.filters["showFeesNA"]) ||
              (props.filters.hasOwnProperty("favoritesOnly") && props.filters["favoritesOnly"])) ?
              "rgb(42,169,112)" : "SlateBlue",
          textDecorationStyle: props.filters && (props.filters.hasOwnProperty("priceCoupon") || (props.filters.hasOwnProperty("moq") &&
            JSON.stringify(props.filters['moq']) !== JSON.stringify([-1, -1]) &&
            JSON.stringify(props.filters['moq']) !== JSON.stringify([-1, 24]) &&
            JSON.stringify(props.filters['moq']) !== JSON.stringify([24, -1])) ||
            props.filters.hasOwnProperty("sortBy") || props.filters.hasOwnProperty("profit") ||
            JSON.stringify(props.filters).includes("found_time") ||
            props.filters.hasOwnProperty("azprice") ||
            (props.filters.hasOwnProperty("oosRA") && props.filters["oosRA"]) ||
            (props.filters.hasOwnProperty("showFeesNA") && props.filters["showFeesNA"]) ||
            (props.filters.hasOwnProperty("favoritesOnly") && props.filters["favoritesOnly"])) && "double"
        }}>More Filters</span>
        <div
          id="modal-js-example"
          className={
            "modal" + (moreSettingsModal ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setMoreSettingsModal(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Advanced Settings
              </p>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => { setCouponModal(true); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  Coupon
                </button>
                {props.supplierType === "retail" || props.supplierType === "retail-arbitrage" ? <button
                  className="button is-rounded lightbutton"
                  onClick={() => setModalSetting("azprice")}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("azprice") &&
                      JSON.stringify(props.filters['azprice']) !== JSON.stringify([-1, -1]) &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  AZPrice
                </button> : <button
                  className="button is-rounded lightbutton"
                  onClick={() => setModalSetting("moq")}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("moq") &&
                      JSON.stringify(props.filters['moq']) !== JSON.stringify([-1, -1]) &&
                      JSON.stringify(props.filters['moq']) !== JSON.stringify([-1, 24]) &&
                      JSON.stringify(props.filters['moq']) !== JSON.stringify([24, -1]) &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                  disabled={props.supplierType === "retail" || props.supplierType === "retail-arbitrage"}
                >
                  MOQ
                </button>}
                <select name="sortby" className="lightbutton" style={{
                  border:
                    (props.filters &&
                      props.filters.hasOwnProperty("sortBy")) ?
                      "3px solid rgb(72,199,142)" : "none", borderRadius: "50px", cursor: "pointer"
                }}
                  onChange={(e) => {
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: JSON.parse(e.target.value),
                    }));
                  }}>
                  <option value="volvo" disabled selected>Sort By</option>
                  <option value={JSON.stringify(["sales_rank", true])}>BSR L→H</option>
                  <option value={JSON.stringify(["sales_rank", false])}>BSR H→L</option>
                  <option value={JSON.stringify(["moq", true])}>MOQ L→H</option>
                  <option value={JSON.stringify(["moq", false])}>MOQ H→L</option>
                  <option value={JSON.stringify(["price", true])}>Price L→H</option>
                  <option value={JSON.stringify(["price", false])}>Price H→L</option>
                  <option value={JSON.stringify(["profit", true])}>Profit L→H</option>
                  <option value={JSON.stringify(["profit", false])}>Profit H→L</option>
                  <option value={JSON.stringify(["roi", true])}>ROI L→H</option>
                  <option value={JSON.stringify(["roi", false])}>ROI H→L</option>
                </select>
              </div>
              <br />
              <button
                className="button is-rounded lightbutton"
                onClick={() => {
                  if (props.activePlanLevel !== "Pro Plan" && props.activePlanLevel !== "Master Plan") {
                    alert("Only Pro & Master Plan subscribers can access this feature!")
                  }
                  else {
                    setModalSetting("profit")
                  }
                }}
                style={{
                  width: "30.5%",
                  marginRight: "4%",
                  border:
                    props.filters &&
                    props.filters.hasOwnProperty("profit") &&
                    "3px solid rgb(72,199,142)",
                }}
              >
                Profit
              </button>
              <button
                className={"button is-rounded"}
                style={{
                  backgroundColor: "#8B3C7E",
                  color: "white",
                  width: "65.5%",
                  fontWeight: "700",
                  border:
                    props.filters &&
                    JSON.stringify(props.filters).includes("found_time") &&
                    "3px solid rgb(72,199,142)",
                  fontSize: props.isMobile ? "2.2vh" : "1vw",
                }}
                onClick={() => {
                  if (props.activePlanLevel !== "Pro Plan" && props.activePlanLevel !== "Master Plan") {
                    alert("Only Pro & Master Plan subscribers can access this feature!")
                  }
                  else {
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["found_time", true],
                    }));
                  }
                }}
              >
                Sort by 'Found time' - Newest First
              </button>
              <br /><br />
              <div style={{ border: "1px solid gray", paddingTop: "15px", paddingBottom: "15px" }}>
                <div style={{ marginTop: "-5px", marginBottom: "7px" }}>
                  Switch Filters: &emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 1 && "pointer" }} disabled={saveFiltersSlot === 1} onClick={() => setSaveFiltersSlot(1)}>1</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 2 && "pointer" }} disabled={saveFiltersSlot === 2} onClick={() => setSaveFiltersSlot(2)}>2</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 3 && "pointer" }} disabled={saveFiltersSlot === 3} onClick={() => setSaveFiltersSlot(3)}>3</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 4 && "pointer" }} disabled={saveFiltersSlot === 4} onClick={() => setSaveFiltersSlot(4)}>4</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 5 && "pointer" }} disabled={saveFiltersSlot === 5} onClick={() => setSaveFiltersSlot(5)}>5</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 6 && "pointer" }} disabled={saveFiltersSlot === 6} onClick={() => setSaveFiltersSlot(6)}>6</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 7 && "pointer" }} disabled={saveFiltersSlot === 7} onClick={() => setSaveFiltersSlot(7)}>7</button>
                </div>
                <div style={{ display: "flex", margin: 'auto', justifyContent: "center" }}>
                  <button
                    className="button is-rounded lightbutton is-success"
                    onClick={() => {
                      localStorage.setItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""), JSON.stringify(props.filters)); setManualRender(mr => mr + 1)
                    }}
                    style={{
                      width: "30%",
                      marginRight: "10px"
                    }}
                  >
                    Save Filters
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null && props.setFilters(JSON.parse(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""))));
                    }}
                    style={{
                      width: "30%",
                    }}
                  >
                    Load Filters
                  </button>
                </div>
                <p style={{ width: "60%", overflowY: "scroll", backgroundColor: "rgba(0,0,0,0.2)", minHeight: manualRender + "px", maxHeight: "10vh", textAlign: "center", margin: "auto", marginTop: "5px" }}>
                  {(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null ?
                    localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) : "").replace("{", "").replace("}", "").replaceAll('"', '').replaceAll(',', ', ').replace("search:,", "").replace("moq:[-1, -1],", "").toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).replace(":", ": ")).join(' ').replaceAll("-1", "N/A")}</p>
              </div>
              <br />
              <label class="checkbox tooltip" style={{
                fontSize: props.isMobile ? "100%" : "0.85vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.2)" }}
                  onChange={(e) => {
                    props.setFilters((filters) => ({
                      ...filters,
                      favoritesOnly: +e.target.checked,
                    }))
                  }
                  }
                  checked={props.filters.favoritesOnly || false}
                />
                &nbsp;&nbsp;Show only Favorites
              </label>&emsp;&emsp;&emsp;
              <label class="checkbox tooltip" style={{
                fontSize: props.isMobile ? "100%" : "0.85vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.2)" }}
                  onChange={(e) => props.setFilters((filters) => ({
                    ...filters,
                    isFBM: filters.hasOwnProperty("isFBM") ? !filters.isFBM : true,
                  }))}
                  checked={!props.filters.hasOwnProperty("isFBM") || !props.filters.isFBM}
                />
                &nbsp;&nbsp;Include FBA Fees in AZFees
              </label><br /><br />
              <label class="checkbox" style={{
                fontSize: props.isMobile ? "100%" : "0.85vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.2)" }}
                  onChange={(e) =>
                    props.setFilters((filters) => ({
                      ...filters,
                      showFeesNA: +e.target.checked,
                    }))
                  }
                  checked={props.filters.showFeesNA || false}
                />
                &nbsp;&nbsp;Show N/A <i className="iconbutton fa fa-info-circle tooltip" aria-hidden="true" style={{ position: "absolute", marginTop: "4px", marginLeft: "5px" }}>
                  <span class="tooltiptext" style={{ padding: "8px", fontSize: "95%", fontFamily: "sans-serif" }}>
                    N/A means that we couldn't fetch the AZ fees, but the product can still be profitable. Check this box to show all opportunities.
                  </span>
                </i>&emsp;
              </label>&emsp;&emsp;&emsp;
              <label class="checkbox" style={{
                fontSize: props.isMobile ? "100%" : "0.85vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.2)" }}
                  onChange={(e) => props.setFilters((filters) => ({
                    ...filters,
                    oosRA: (props.filters.hasOwnProperty("oosRA") ? !props.filters['oosRA'] : true),
                  }))}
                  checked={props.filters.hasOwnProperty("oosRA") && props.filters['oosRA']}
                />
                &nbsp;&nbsp;Show OOS Online{" "}
                <i className="iconbutton fa fa-info-circle tooltip" aria-hidden="true" style={{ position: "absolute", marginTop: "4px", marginLeft: "5px" }}>
                  <span className="tooltiptext" style={{ padding: "8px", fontSize: "95%", fontFamily: "sans-serif" }}>
                    Show products that are currently out of stock online. Save them to your favorites to track until they're available online again or purchase them in-store (Retail Arbitrage)
                  </span>
                </i>
              </label>
              <br /><br />
              <label class="checkbox tooltip" style={{
                fontSize: props.isMobile ? "100%" : "0.85vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.2)" }}
                  onChange={(e) => props.setFilters((filters) => ({
                    ...filters,
                    threepwalmart: (props.filters.hasOwnProperty("threepwalmart") ? !props.filters['threepwalmart'] : true),
                  }))}
                  checked={props.filters.hasOwnProperty("threepwalmart") && props.filters['threepwalmart']}
                />
                &nbsp;&nbsp;Filter out 3P sellers on Walmart{" "}
              </label>
              <br />
              <hr />
              <strong>Permanent Settings{" "}
                <i className="iconbutton fa fa-info-circle tooltip" aria-hidden="true" style={{ position: "absolute", marginTop: "5px", marginLeft: "5px" }}>
                  <span className="tooltiptext" style={{ padding: "8px", fontSize: "95%", fontFamily: "sans-serif" }}>
                    In addition, you can enter fees, such as inbound shipping cost and prep fee, in the 'Costs' column. These will be permanent as well until you overwrite them.
                  </span>
                </i></strong>
              <br /><br />
              <div>
                <input
                  className="input searchproduct"
                  type="number"
                  step={0.5}
                  style={{
                    backgroundColor: "rgb(179,127,170)",
                    color: "white",
                    fontSize: props.isMobile ? "1.9vh" : ".95vw",
                    width: "29%"
                  }}
                  value={salesTax}
                  placeholder={"Sales Tax %"}
                  onChange={(e) => {
                    setSalesTax(e.target.value);
                  }}
                />
                <button
                  className="button is-success"
                  style={{
                    color: "white",
                    fontSize: props.isMobile ? "1.9vh" : ".95vw",
                    marginRight: "10%"
                  }}
                  onClick={() => {
                    localStorage.setItem("salesTax", salesTax === "" ? 0 : salesTax)
                    props.setFilters((filters) => ({
                      ...filters,
                      salesTax: salesTax ? parseFloat(salesTax) : 0,
                    }));
                  }}
                >
                  <i className="fa fa-check" />
                </button>
                <input
                  className="input searchproduct"
                  id="excludeBrandsInput"
                  type="text"
                  style={{
                    backgroundColor: "rgb(179,127,170)",
                    color: "white",
                    fontSize: props.isMobile ? "1.9vh" : ".95vw",
                    width: "30%",
                  }}
                  value={brandExc}
                  placeholder={"Exclude Brand"}
                  onChange={(e) => {
                    setBrandExc(e.target.value);
                  }}
                />
                <button
                  className="button is-success"
                  style={{
                    color: "white",
                    fontSize: props.isMobile ? "1.9vh" : ".95vw",
                  }}
                  onClick={() => {
                    if (brandExc.length > 0) {
                      localStorage.setItem("excludeBrands", (localStorage.getItem("excludeBrands") ? localStorage.getItem("excludeBrands") + "," : "") + brandExc);
                      setBrandExc("");
                      document.getElementById("excludeBrandsInput").focus();
                      props.setFilters((filters) => ({
                        ...filters,
                        excludeBrands: localStorage.getItem("excludeBrands") ?? "",
                      }));
                    }
                  }}
                >
                  <i className="fa fa-plus" />
                </button>
                <br />
                <span style={{ color: "green", marginLeft: "-38%", fontSize: "0.75vw", position: "absolute" }}>Saved: {localStorage.getItem("salesTax") ?? 0}% Sales Tax</span>
                <span render={manualRender} style={{ color: "#8B3C7E", overflowY: "scroll", overflowX: "hidden", wordWrap: "break-word", marginLeft: "55%", fontSize: "0.75vw", backgroundColor: "rgba(50, 50, 50,0.3)", padding: "5px", height: "8vh", display: "block", width: "40%" }}>
                  {localStorage.getItem("excludeBrands") ?? "No brands are excluded."}
                </span>
                <div style={{ display: "flex" }}>
                  <button onClick={() => {
                    localStorage.removeItem("excludeBrands"); setManualRender(mr => mr + 1); props.setFilters((filters) => ({
                      ...filters,
                      excludeBrands: "",
                    }));
                  }} className="button" style={{ color: "#8B3C7E", marginLeft: "55%", fontSize: "0.75vw", border: "3px solid #8B3C7E", display: "block", width: "20%", cursor: "pointer", paddingTop: "4px" }}>Reset</button>
                  <button onClick={() => {
                    const inputString = localStorage.getItem("excludeBrands") ?? "";
                    const csvContent = `Brand\n${inputString.replace(/,/g, '\n')}`;
                    const link = document.createElement("a");

                    link.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
                    link.download = "excluded_brands.csv";
                    link.click();
                  }} className="button" style={{ color: "#8B3C7E", fontSize: "0.75vw", border: "3px solid #8B3C7E", display: "block", width: "10%", cursor: "pointer", paddingTop: "4px" }}><i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                  <label for="exfile" style={{ color: "#8B3C7E", fontSize: "0.75vw", border: "3px solid #8B3C7E", display: "block", width: "10%", cursor: "pointer", paddingTop: "4px", borderRadius: "3px" }}><i class="fa fa-upload" aria-hidden="true"></i></label>
                  <input type="file" id="exfile" accept=".csv" onChange={(e) => {
                    const file = e.target.files[0];
                    if (!file) return;

                    const reader = new FileReader();
                    reader.onload = (event) => {
                      const text = event.target.result;
                      const rows = text.replace("\r", "").replace("\t", "").split('\n').map(r => r.replace("\r", "")).filter(item => item !== "" && item !== " ");
                      const headers = rows[0].replace("\r", "").split(',');

                      const brandIndex = headers.indexOf('Brand');
                      if (brandIndex === -1) {
                        alert('Bad format: Missing "Brand" column.');
                        return;
                      }

                      const brands = rows.slice(1).map(row => row.split(',')[brandIndex].replace('\"', "").replace('?', "")).join(',');
                      if (brands.length > 0) {
                        localStorage.setItem("excludeBrands", brands);
                      } else {
                        localStorage.removeItem("excludeBrands")
                      }
                      props.setFilters((filters) => ({
                        ...filters,
                        excludeBrands: brands,
                      }));
                      setManualRender(mr => mr + 1);
                    };

                    reader.readAsText(file);

                    e.target.value = '';
                  }} className="button" style={{ visibility: "hidden", width: "10%", position: "absolute" }} />
                </div>
              </div>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setMoreSettingsModal(false)
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {couponModal && (
          <div
            id="modal-js-example"
            className={
              "modal" + (couponModal ? " is-active" : "")
            }
          >
            <div className="modal-background" onClick={() => {
              setCouponModal(false);
            }}></div>
            <div
              className="modal-content"
              style={{ width: props.isMobile ? "90vw" : "38vw" }}
            >
              <div className="box" style={{ textAlign: "center" }}>
                <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                  Add Price Coupon Percentage
                </p>
                <p>
                  Everything will be recalculated with this coupon. It will show you new products that now suit your criteria after including it.
                </p>
                <br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      props.setFilters((filters) => ({
                        ...filters,
                        priceCoupon: 0,
                      })); setCouponModal(false);
                    }}
                    style={{
                      border:
                        props.filters &&
                        props.filters.hasOwnProperty("priceCoupon") &&
                        props.filters["priceCoupon"] === 0 &&
                        "3px solid rgb(72,199,142)",
                      marginRight: "1vw"
                    }}
                  >
                    0%
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      props.setFilters((filters) => ({
                        ...filters,
                        priceCoupon: 5,
                      })); setCouponModal(false);
                    }}
                    style={{
                      border:
                        props.filters &&
                        props.filters.hasOwnProperty("priceCoupon") &&
                        props.filters["priceCoupon"] === 5 &&
                        "3px solid rgb(72,199,142)",
                      marginRight: "1vw"
                    }}
                  >
                    5%
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      props.setFilters((filters) => ({
                        ...filters,
                        priceCoupon: 10,
                      })); setCouponModal(false);
                    }}
                    style={{
                      border:
                        props.filters &&
                        props.filters.hasOwnProperty("priceCoupon") &&
                        props.filters["priceCoupon"] === 10 &&
                        "3px solid rgb(72,199,142)",
                      marginRight: "1vw"
                    }}
                  >
                    10%
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      props.setFilters((filters) => ({
                        ...filters,
                        priceCoupon: 15,
                      })); setCouponModal(false);
                    }}
                    style={{
                      border:
                        props.filters &&
                        props.filters.hasOwnProperty("priceCoupon") &&
                        props.filters["priceCoupon"] === 15 &&
                        "3px solid rgb(72,199,142)",
                      marginRight: "1vw"
                    }}
                  >
                    15%
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      props.setFilters((filters) => ({
                        ...filters,
                        priceCoupon: 20,
                      })); setCouponModal(false);
                    }}
                    style={{
                      border:
                        props.filters &&
                        props.filters.hasOwnProperty("priceCoupon") &&
                        props.filters["priceCoupon"] === 20 &&
                        "3px solid rgb(72,199,142)",
                      marginRight: "1vw"
                    }}
                  >
                    20%
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      props.setFilters((filters) => ({
                        ...filters,
                        priceCoupon: 25,
                      })); setCouponModal(false);
                    }}
                    style={{
                      border:
                        props.filters &&
                        props.filters.hasOwnProperty("priceCoupon") &&
                        props.filters["priceCoupon"] === 25 &&
                        "3px solid rgb(72,199,142)",
                      marginRight: "1vw"
                    }}
                  >
                    25%
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    style={{
                      border:
                        props.filters &&
                        props.filters.hasOwnProperty("priceCoupon") &&
                        ![0, 5, 10, 15, 20, 25].includes(props.filters["priceCoupon"]) &&
                        "3px solid rgb(72,199,142)",
                      marginRight: "1vw",
                      cursor: "default"
                    }}
                  >
                    <input onBlur={(e) => {
                      if (e.target.value && e.target.value > 0) {
                        props.setFilters((filters) => ({
                          ...filters,
                          priceCoupon: parseFloat(e.target.value),
                        })); setCouponModal(false);
                      }
                    }} type="number" placeholder={props.filters && props.filters.hasOwnProperty("priceCoupon") && props.filters["priceCoupon"]} style={{ width: '2.5vw' }} />%
                  </button>
                </div>
                <br /><br />
                <div className="columns is-mobile is-centered">
                  <button
                    className="button is-rounded is-danger"
                    style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                    onClick={() => {
                      setCouponModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>)}
      </div>}
      <div className="column is-2-desktop is-4-mobile" style={{ marginLeft: !props.isMobile && "1.5vw", marginTop: !props.isMobile && "-6vh", width: props.isMobile && "100%" }}>
        <button
          className="button is-rounded darkbutton"
          onClick={() => setShowHiddenProducts(true)}
          style={{
            float: !props.isMobile && "right",
            marginBottom: "1vh",
            marginRight: "1.25vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
          }}
        >
          My Hidden Products
        </button>
        <button
          className="button is-rounded darkbutton"
          onClick={() => setFilterSupplierModalOpen(true)}
          style={{
            float: !props.isMobile && "right",
            marginRight: "1.25vw",
            border:
              props.filters &&
              (props.filters.hasOwnProperty("sourceName") ||
                props.filters.hasOwnProperty("supplierTopMinPurchase") ||
                props.filters.hasOwnProperty("supplierAimAmazon")) &&
              "3px solid rgb(72,199,142)",
          }}
          id="filtersupTt"
        >
          Filter Suppliers
        </button>
        {props.isMobile && <button
          className="button is-rounded darkbutton"
          onClick={() => setProModalOpen(true)}
          style={{
            fontSize: props.isMobile ? "100%" : "0.85vw",
            border: props.filters && (props.filters.hasOwnProperty("profit") || JSON.stringify(props.filters).includes("found_time")) && "3px solid rgb(72,199,142)",
          }}
        >
          Pro Features
        </button>}
      </div>

      <ModalForSettings
        filters={props.filters}
        setFilters={props.setFilters}
        modalSetting={modalSetting}
        setModalSetting={setModalSetting}
        isMobile={props.isMobile}
        supplierType={props.supplierType}
      />
      <ModalForSupplierFilter
        filters={props.filters}
        setFilters={props.setFilters}
        filterSupplierModalOpen={filterSupplierModalOpen}
        setFilterSupplierModalOpen={setFilterSupplierModalOpen}
        isMobile={props.isMobile}
        suppliersList={props.suppliersList}
        supplierType={props.supplierType}
      />
      {showHiddenProducts && <ModalForHiddenProducts
        isMobile={props.isMobile}
        setShowHiddenProducts={setShowHiddenProducts}
        showHiddenProducts={showHiddenProducts}
      />}
      <ModalForProFeatures
        isMobile={props.isMobile}
        setProModalOpen={setProModalOpen}
        proModalOpen={proModalOpen}
        setFilters={props.setFilters}
        filters={props.filters}
        data={props.data}
        setModalSetting={setModalSetting}
        oos={false} />
    </div>
  );
}

export default FilterBar;
