import React, { useState, useEffect } from "react";
import "./Products.css";
import CardSwipper from "./Mobile/CardSwipper";
import ProductsTable from "./Components/ProductsTable";
import FilterBar from "./Components/FilterBar";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function JimProductsPage(props) {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [sortASC, setSortASC] = useState(false);
  const suppliersList = ["doitbest-excel", "doitbest", "ericksonmfg"];
  const [data, setData] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [noPermissions, setNoPermissions] = useState(false);
  const [showAdv, setShowAdv] = useState(localStorage.getItem("showAdv") === null ? true : JSON.parse(localStorage.getItem("showAdv")));

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    setFilters({
      search: urlParams.has("asin") ? urlParams.get('asin') : "",
      sourceName: ["doitbest-excel", "doitbest", "ericksonmfg"],
    });
  }, [props.supplierType]);

  useEffect(() => {
    if (filters !== null) {
      setPage(1);
    }
  }, [filters]);

  function removeDuplicates(list) {
    var uniqueObjects = {};
    var result = [];

    list.forEach(function(obj) {
        var key = obj.asin + obj.source_name;

        if (!uniqueObjects[key]) {
            uniqueObjects[key] = true;
            result.push(obj);
        }
    });

    return result;
  }

  useEffect(() => {
    if (filters !== null) {
      const loadProducts = async () => {
        setIsProductsLoading(true);
        const accessToken = await getAccessTokenSilently();
        const tempFilters = { ...filters, "cost": localStorage.getItem("costPrep") || 0}
        tempFilters['cost'] = parseFloat(tempFilters['cost']) > 20 ? 0 : parseFloat(tempFilters['cost'])
        fetch(
          "https://server.nepeto.com/load_products_by_page/" +
            page +
            "/" +
            JSON.stringify(tempFilters) +
            "/" +
            props.supplierType +
            "/",
          { mode: "cors", headers: { Authorization: `Bearer ${accessToken}` } }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data === "No permissions") {
              setNoPermissions(true);
            } else {
              setNoPermissions(false);
              if (data.length > 0 && !props.isMobile && !(new Date(data[0].title.split("Last Update: ")[1]).toDateString().includes("Invalid"))) {
                let filteredData = removeDuplicates(data.filter(product => (((new Date() - new Date(product.title.split("Last Update: ")[1])) / 1000 / 60 / 60) <= 100)))
                if (filteredData.length > 0) {
                  setData(filteredData);
                  setIsProductsLoading(false);
                } else {
                  setPage(page => page + 1)
                }
              } else {
                setData(data)
                setIsProductsLoading(false);
              }
            }
          });
      };
      if (!isLoading && isAuthenticated) {
        loadProducts();
      }
    }
  }, [
    filters,
    page,
    props.supplierType,
    getAccessTokenSilently,
    isLoading,
    props.isMobile,
    isAuthenticated,
  ]);

  const reportMismatch = async (asin) => {
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/report_mismatch/" + asin + "/" + props.supplierType + "/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };

  if (noPermissions) {
    navigate("/pricing");
  }
  if (!isLoading && !isAuthenticated) {
    return (
      <div style={{ textAlign: "center", marginBottom: "80vh" }}>
        <Helmet>
          <title>
            Nepeto - Products from {props.supplierType}
            {props.supplierType !== "distributors" ? " suppliers" : "/wholesale"}
          </title>
          <meta
            name="description"
            content="Discover thousands of profitable products for reselling on Amazon from various suppliers. We provide all the essential data to make informed decisions. Easily access supplier websites, Amazon links, and additional tools for a successful reselling experience."
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, supplier, distributors, wholesale, retail, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
        <br />
        <br />
        <p
          style={{
            width: "80vw",
            margin: "auto",
            fontSize: "200%",
            boxShadow: "0px 0px 5px 1px #8c3e7c",
            padding: "2%",
          }}
        >
          To use Nepeto, sign up and subscribe to one of our plans. You can
          start with a free sign-up and enjoy a free trial!
          <br />
          <br />
          <button
            className="button is-rounded is-size-6-mobile is-size-6-tablet"
            style={{
              backgroundColor: "#32CD32",
              margin: "auto",
              color: "#ffffff",
            }}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                },
                appState: { returnTo: "/pricing?s=1" },
              })
            }
          >
            Sign up for Free!
          </button>
        </p>
      </div>
    );
  }

  return (
    <div className="products">
      <Helmet>
        <title>
          Nepeto - Products from {props.supplierType}
          {props.supplierType !== "distributors" ? " suppliers" : "/wholesale"}
        </title>
        <meta
          name="description"
          content="Discover thousands of profitable products for reselling on Amazon from various suppliers. We provide all the essential data to make informed decisions. Easily access supplier websites, Amazon links, and additional tools for a successful reselling experience."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, supplier, distributors, wholesale, retail, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "2vh",
        }}
      >
        Products from {props.supplierType}
        {props.supplierType !== "distributors" ? " suppliers" : "/wholesale"}
      </h1>
      {!props.isMobile && 
      <span className="tooltip" style={{position: 'absolute', backgroundImage: "linear-gradient(to right, transparent -15%, rgba(230, 206, 222, 0.2) 50%, transparent 115%)", fontSize: props.isMobile ? "1.5vh" : "1vw", color: "#8c3e7c", left: "42.5%", width: "15%", textAlign: "center"}}>
        &nbsp;{props.matches}/50 Mismatches Found<span style={{fontSize:"65%"}}>(?)</span>
        <span class="tooltiptext" style={{padding: "8px", fontSize: "80%"}}>Users who report 50 true mismatches will receive a free subscription!</span>
        </span>}
      
      {filters !== null && (
        <FilterBar
          isMobile={props.isMobile}
          setFilters={setFilters}
          filters={filters}
          suppliersList={suppliersList}
          supplierType={"distributors"}
          data={data}
          showAdv={showAdv}
          setShowAdv={setShowAdv}
          activePlanLevel={props.activePlanLevel}
        />
      )}

      {isProductsLoading || filters === null ? (
        <progress className="progress" style={{ marginBottom: "66vh" }} />
      ) : props.isMobile ? (
        <CardSwipper data={data} setPage={setPage} hideCredits={props.hideCredits}/>
      ) : (
        <ProductsTable
          data={data}
          setFilters={setFilters}
          filters={filters}
          setSortASC={setSortASC}
          sortASC={sortASC}
          setPage={setPage}
          page={page}
          isMobile={props.isMobile}
          reportMismatch={reportMismatch}
          supplierType="distributors"
          hideCredits={props.hideCredits}
          showAdv={showAdv}
          setShowAdv={setShowAdv}
          sellerId={props.sellerId}
          refreshToken={props.refreshToken}
          silentjim={true}
        />
      )}
    </div>
  );
}
