import React, { useState, useEffect } from "react";
import "../Products/Products.css";
import { useAuth0 } from "@auth0/auth0-react";
import OOSProductsTable from "./Components/OOSProductsTable";
import OOSCardSwipper from "./Components/OOSCardSwipper";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import OOSFilterBar from "./Components/OOSFilterBar";

export default function OOSProductsPage(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [noPermissions, setNoPermissions] = useState(false);
  const [filters, setFilters] = useState({search: urlParams.has("asin") ? urlParams.get('asin') : ""});
  const [suppliersList, setSuppliersList] = useState([]);

  useEffect(() => {
    const loadProducts = async () => {
      setIsProductsLoading(true);
      const accessToken = await getAccessTokenSilently();
      fetch(
        "https://server.nepeto.com/load_oos_products_by_page/" +
          page +
          "/" +
          JSON.stringify(filters) +
          "/",
        { mode: "cors", headers: { Authorization: `Bearer ${accessToken}` } }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data === "No permissions") {
            setNoPermissions(true);
          } else {
            setNoPermissions(false);
            if (data.length > 0 && !props.isMobile && !(new Date(data[0].title.split("Last Update: ")[1]).toDateString().includes("Invalid"))) {
              let filteredData = urlParams.has("asin") ? data : data.filter(product => (((new Date() - new Date(product.title.split("Last Update: ")[1])) / 1000 / 60 / 60) <= 144))
              if (filteredData.length > 0) {
                setData(filteredData);
                setIsProductsLoading(false);
              } else {
                setPage(page => page + 1)
              }
            } else {
              setData(data)
              setIsProductsLoading(false);
            }
          }
        });
    };

    if (!isLoading && isAuthenticated) {
      loadProducts();
    }
  }, [page, filters, props.isMobile, getAccessTokenSilently, isLoading, isAuthenticated]);

  useEffect(() => {
    const loadSuppliersList = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/get_suppliers_list", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== "No permissions") {
            setSuppliersList(data)
          }
        });
    }
    if (!isLoading && isAuthenticated) {
      loadSuppliersList()
    }
  },[getAccessTokenSilently,isLoading,isAuthenticated])
  
  const reportMismatch = async (asin) => {
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/report_mismatch/" + asin + "/oos/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };

  if (noPermissions) {
    navigate("/pricing");
  }
  if (!isLoading && !isAuthenticated) {
    return (
      <div style={{ textAlign: "center", marginBottom: "80vh" }}>
        <Helmet>
          <title>Nepeto - Out of Stock Products</title>
          <meta
            name="description"
            content="Explore Amazon's out-of-stock products. Discover profitable opportunities with no competition. You set the price and rules."
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, out of stock, oos, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
        <br />
        <br />
        <p
          style={{
            width: "80vw",
            margin: "auto",
            fontSize: "200%",
            boxShadow: "0px 0px 5px 1px #8c3e7c",
            padding: "2%",
          }}
        >
          To use Nepeto, sign up and subscribe to one of our plans. You can
          start with a free sign-up and enjoy a free trial!
          <br />
          <br />
          <button
            className="button is-rounded is-size-6-mobile is-size-6-tablet"
            style={{
              backgroundColor: "#32CD32",
              margin: "auto",
              color: "#ffffff",
            }}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                },
                appState: { returnTo: "/pricing?s=1" },
              })
            }
          >
            Sign up for Free!
          </button>
        </p>
      </div>
    );
  }

  return (
    <div className="products">
      <Helmet>
        <title>Nepeto - Out of Stock Products</title>
        <meta
          name="description"
          content="Explore Amazon's out-of-stock products. Discover profitable opportunities with no competition. You set the price and rules."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, out of stock, oos, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "1vh",
        }}
      >
        Products that are Out-of-Stock on Amazon
      </h1>
      {isProductsLoading ? (
        <progress className="progress" style={{ marginBottom: "77vh" }} />
      ) : props.isMobile ? (
        <>
          <OOSFilterBar
            isMobile={props.isMobile}
            setFilters={setFilters}
            filters={filters}
            suppliersList={suppliersList}
            supplierType={props.supplierType}
            data={data}
          />
          <OOSCardSwipper data={data} setPage={setPage} hideCredits={props.hideCredits}/>
        </>
      ) : (
        <>
          <OOSFilterBar
            isMobile={props.isMobile}
            setFilters={setFilters}
            filters={filters}
            suppliersList={suppliersList}
            supplierType={props.supplierType}
            data={data}
          />
          <OOSProductsTable
            data={data}
            setPage={setPage}
            page={page}
            isMobile={props.isMobile}
            reportMismatch={reportMismatch}
            hideCredits={props.hideCredits}
            sellerId={props.sellerId}
            refreshToken={props.refreshToken}
          />
        </>
      )}
    </div>
  );
}
