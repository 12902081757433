import { useState } from "react";
import { retailLogos } from "../Products/Components/RetailSuppliersLogos";
import { useAuth0 } from "@auth0/auth0-react";

const SupplierModal = (props) => {
  const [suggestSupplier, setSuggestSupplier] = useState("");
  const {
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();

  return (
    <div className={"modal" + (props.isActive ? " is-active" : "")}>
      <div className="modal-background"></div>
      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "50vw", minHeight: "97vh" }}
      >
        <div className="box" style={{ textAlign: "center", fontWeight: "600" }}>
          <div className="floatingAccept">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : "1vw", boxShadow: "0px 0px 5px 0px black" }}
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
          <h2 style={{ fontSize: props.isMobile ? "2.5vh" : "1.5vw", marginBottom: "20px" }}>
            Our Supported Suppliers for the Live Search
          </h2>

          <div className="columns is-multiline is-mobile is-centered">
            {props.suppliersList.map((supplier, index) => (
              <div class="column is-3" key={index}>
                <label style={{ display: "flex", position: "relative", boxShadow: "0px 0px 5px 0px #8c3e7c", borderRadius: "10px", padding: "5px", flexDirection: "column", alignItems: "center", height: props.isMobile ? "40vw" : "5vw", margin: "auto", justifyContent: "center" }}>
                  <input
                    type="checkbox"
                    name={supplier}
                    value={supplier}
                    style={{ opacity: "0" }}
                  />{" "}
                  {retailLogos.hasOwnProperty(supplier) && <img
                    alt="logo"
                    class="transparent-white"
                    src={`/assets/logos/${retailLogos[supplier]}.png`}
                    loading="lazy"
                    onClick={() => window.open(`https://${supplier}.com`)}
                    style={{ maxWidth: !props.isMobile && "70%", marginTop: "-10%", maxHeight: props.isMobile ? "85%" : '65%' }}
                  />}
                  {props.underMain.includes(supplier) && <span style={{fontSize: "80%", color: "red"}}>Under maintenance</span>}
                  <br/>
                  <span  onClick={() => window.open(`https://${supplier}.com`)} style={{ fontWeight: "500", position: "absolute", bottom: retailLogos.hasOwnProperty(supplier) && "0", fontSize: !props.isMobile && "1vw" }}>{supplier.slice(0, 19)}<br />{supplier.slice(19)}</span>
                </label>
              </div>
            ))}
          </div>
          <br />

          <form
            action="https://server.nepeto.com/email"
            method="post"
            accept-charset="UTF-8"
            className="box p-5"
            style={{
              border: "1px solid rgba(0,0,0,0.12)",
              marginBottom: "0",
              width: props.isMobile ? "100%" : "60%",
              margin: "auto"
            }}
          >
            <label className="is-block mb-4">
              <span className="is-block mb-2" style={{ fontSize: "100%" }}>
                Can't find your favorite supplier? Let us know their name,
                and we will add them!
              </span>
              <div style={{}}>
                <input
                  name="name"
                  type="text"
                  className="input"
                  placeholder="Suggest a new supplier..."
                  style={{ width: "75%" }}
                  onChange={(e) => setSuggestSupplier(e.target.value.replace(" ", "").replace("'", "").toLowerCase())}
                />
                <button
                  type="submit"
                  className="button px-4"
                  style={{
                    backgroundColor: "#8c3e7c",
                    color: "white",
                    width: "25%",
                  }}
                  onClick={(e) => {
                    if (props.suppliersList.includes(suggestSupplier)) {
                      alert(`We already have ${suggestSupplier} in Nepeto!`);
                      e.preventDefault();
                    }
                    if (suggestSupplier === "") {
                      alert('Please enter supplier name!');
                      e.preventDefault();
                    }
                  }}
                >
                  Send
                </button>
              </div>
            </label>
            <div className="mb-4">
              <div
                style={{
                  textIndent: "-99999px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  position: "absolute",
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="_gotcha"
                  tabindex="-1"
                  autocomplete="off"
                />
                <input
                  type="text"
                  name="what_is_it"
                  value={"new_supplier_live_search"}
                  tabindex="-1"
                  autocomplete="off"
                />
                <input
                  type="text"
                  name="email"
                  value={(!isLoading && isAuthenticated) ? user.email : "N/A"}
                  tabindex="-1"
                  autocomplete="off"
                />
              </div>
            </div>
          </form>

          <br /><br />
          <br /><br />
        </div>
      </div>
    </div>
  )
};

export default SupplierModal;