import React, { useEffect, useState } from "react";
import "../Pricing.css";
import ListItem from "./ListItem";
import SubscribeButtonPayPal from "./SubscribeButtonPayPal";
import SubscribeButtonPPG from "./SubscribeButtonPPG";

function PriceCard(props) {
  const [readyToPay, setReadyToPay] = useState(false);

  useEffect(() => {
    setReadyToPay(false);
  }, [props.isMonthly])


  return (
    <div className="column" style={{ marginBottom: "5vh" }}>
      <div className="card is-3 priceCard" style={!props.isMonthly ? {boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
          animation: 'bfglow 3s infinite alternate',} : {}}>
        <div className="columns is-mobile mb-0" style={{ height: "10vh" }}>
          <div className="column is-8" style={{ paddingBottom: "0" }}>
            <strong className="textCard">{props.plan.name === "GA" ? <span style={{ fontSize: props.isMobile ? "40%" : "55%" }}>MEET WİTH NEPETO & ABRA</span> : props.plan.name}</strong>
          </div>

          <div className="column is-4" style={{ paddingBottom: "0" }}>
            <img
              src={props.plan.img}
              alt="Card"
              style={{ height: "100%", padding: "10%" }}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "-3vh",
            fontSize: "380%",
            fontWeight: "600",
            textAlign: "center",
            color: "rgb(140, 62, 124)",
            lineHeight: props.plan.isEnterprise && props.activePlanLevel === 0 ? "70%" : "100%",
          }}
        >
          {!props.isMonthly && <span style={{color: "red", fontSize: "50%", textDecoration: "line-through"}}>$
          {props.plan.isEnterprise && props.activePlanLevel === 0 ? "0" : Math.ceil((props.isMonthly
            ? props.plan.price_monthly
            : props.plan.price_annually))}</span>}
          $
          <span style={!props.isMonthly ? {textShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
          animation: 'bftglow 3s infinite alternate',} : {}}>{props.plan.isEnterprise && props.activePlanLevel === 0 ? "0" : ((props.isMonthly
            ? (props.plan.price_monthly * (!props.isMonthly ? 0.7 : 1))
            : (props.plan.price_annually * (!props.isMonthly ? 0.7 : 1) )).toFixed((!props.isMonthly ? 2 : 0)))}</span>
          {!props.plan.isEnterprise || props.activePlanLevel !== 0 ? (
            <span style={{ fontSize: "50%" }}>/mo</span>
          ) : <><br /><span style={{ fontSize: "50%", lineHeight: "30%" }}>First week free!<br />Then ${Math.ceil((props.isMonthly
            ? (props.plan.price_monthly)
            : (props.plan.price_annually)))}/month.</span><br /><br /></>}
        </div>

        {!props.noDiscount && (!props.isMonthly && (
          <><p className="additext" style={{ fontWeight: "900", fontSize: props.isMobile ? "100%" : "140%", textAlign: "center", marginTop: "12px", }}>
            ${(props.plan.price_annually * 0.7 * 12).toFixed(2)} billed annually
          </p>
          </>
        ))}

        <div class="card-content" style={{ marginTop: "-1vh", height: props.higherCard ? props.isMobile ? "100vh" : "70vh" : (props.plan.isEnterprise && props.activePlanLevel === 0 ? "44.7vh" : "55vh") }}>
          <div>
            {props.plan.checkList.filter(item => {
              if (item.includes("Free Trial")) {
                return props.activePlanLevel === 0
              } else {
                return true
              }
            }).map((item) => {
              return <ListItem sent={item}></ListItem>;
            })}
            {localStorage.getItem('affiliate') === "ga" && <ListItem sent={"Nepeto's expert 1-on-1 welcome call (60 min) - Let's talk about Amazon and the right business mindset"}></ListItem>}
          </div>
        </div>
        {!props.isMonthly && <p className="additext" style={{ fontWeight: "700", fontSize: props.isMobile ? "100%" : "100%", textAlign: "center" }}>
          ${(props.plan.price_annually * 0.7 * 12).toFixed(2)} billed annually
        </p>}
        <footer
          class="card-footer"
          style={{ borderTop: "none", paddingBottom: "1vh", display: "grid" }}
        >
          <SubscribeButtonPPG price={props.plan.price_monthly} monthly={props.isMonthly} prd_id={props.isMonthly ? (props.activePlanLevel === 0 ? props.plan.prd_id_monthly : props.plan.prd_id_monthly_no_trial) : (props.activePlanLevel === 0 ? props.plan.prd_id_annually : props.plan.prd_id_annually_no_trial)} text={"Click here to start!"} />
          {/* <span style={{ margin: "auto", marginBottom: "20px" }}>-OR-</span>
          {readyToPay ?
            <div style={{ margin: "auto", textAlign: "center" }}>
              <SubscribeButtonPayPal
                plan={props.plan.name}
                plan_id={props.isMonthly ? (props.activePlanLevel === 0 ? props.plan.plan_id_monthly : props.plan.plan_id_monthly_no_trial) : (props.activePlanLevel === 0 ? props.plan.plan_id_annually : props.plan.plan_id_annually_no_trial)}
                prd_id={props.isMonthly ? (props.activePlanLevel === 0 ? props.plan.prd_id_monthly : props.plan.prd_id_monthly_no_trial) : (props.activePlanLevel === 0 ? props.plan.prd_id_annually : props.plan.prd_id_annually_no_trial)}
                planLevel={props.plan.planLevel}
                monthly={props.isMonthly}
                activePlanLevel={props.activePlanLevel}
                price={Math.ceil((props.isMonthly ? props.plan.price_monthly : props.plan.price_annually))}
                traObj={{
                  plan: props.plan.name,
                  plan_obj: props.plan,
                  has_trial: props.activePlanLevel === 0,
                  planLevel: props.plan.planLevel,
                  monthly: props.isMonthly,
                  activePlanLevel: props.activePlanLevel,
                  price: Math.ceil((props.isMonthly ? props.plan.price_monthly : props.plan.price_annually)),
                  isMobile: props.isMobile
                }}
              />
            </div> :
            <button
              className="button is-rounded is-size-6-mobile is-size-6-tablet"
              style={{ borderColor: "#48C78E", margin: "auto", color: "#48C78E", backgroundColor: "transparent", fontWeight: "900" }}
              onClick={(e) => setReadyToPay(true)}
            >Pay with PayPal</button>} */}
        </footer>
      </div>
    </div>
  );
}

export default PriceCard;
